<template>
  <div>
    <template v-if="errors.code">
      <a-result :status="String(errors.code)" :title="errors.code" :sub-title="errors.message">
        <template #extra>
          <a-button type="primary" @click="$router.back()">
            Back to previous page
          </a-button>
        </template>
      </a-result>
    </template>
    <template v-else>
      <div
        class="mb-4"
        style="position: sticky; top: 78px; z-index: 5; background-color: white; margin: -20px -35px 0; padding: 15px 30px;"
      >
        <a-breadcrumb
          separator=">"
        >
          <a-breadcrumb-item>
            <router-link :to="{ path: '/product', query: { business_id: businessId, workspace_id: $route.query.workspace_id } }">
              {{ $t('product.title') }}
            </router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            <router-link :to="{ path: '/product', query: { business_id: businessId, workspace_id: $route.query.workspace_id } }">
              {{ $t('product.listProduct') }}
            </router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            {{ business.business_title }}
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            Ubah Produk
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>

      <CreateProductForm
        ref="form"
        :loading="loading"
        :business="business"
        :product="product"
        :permission="permission"
        :get-detail="fetchProductDetail"
      />
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import CreateProductForm from '@/components/Store/Distributor/ProductForm/index.vue'
import { getProductDetail } from '@/api/product'
import { getDetailProductRedeem } from '@/api/channels/distributor'

export default defineComponent({
  name: 'EditProductPage',
  components: { CreateProductForm },
  beforeRouteLeave(to, from, next) {
    return this.$refs.form ? this.$refs.form.beforeRouteLeave(to, from, next) : next()
  },
  data() {
    return {
      product: {},
      errors: {
        message: null,
        code: null,
      },
      loading: false,
    }
  },
  computed: {
    productId() {
      const id = this.$route.query.id
      return Array.isArray(id) ? id.join(',') : id
    },
    
    /**
     * @returns {string}
     */
    businessId() {
      return String(this.$route.query['business_id'] || '')
    },

    /**
     * @returns {import('@/types/utils').Business}
     */
    business() {
      return this.$store.state.user.businessList.find(business => business.business_id === this.businessId) || {}
    },
    permission() {
      return this.$store.getters['user/can']('product-list')
    },
  },
  // watch: {
  //   permission(newValue) {
  //     if(!newValue.length) {
  //       this.$router.push({path: '/error/403', query: {...this.$route.query}})
  //     }
  //   },
  // },
  mounted() {
    this.$store.commit('products/SET_CHANNEL', true)
    this.fetchProductDetail()
  },
  methods: {
    async fetchProductDetail() {
      this.loading = true
      if (!this.productId) return

      this.errors.message = null

      const bodyProduct = { 
        id: this.productId,
        params: { business_id: this.businessId },
      }
      const bodyRedeem = {
        business_id: this.businessId,
        params: {
          product_id: this.productId,
          channel_id: this.$route.params.id,
        },
      }

      await Promise.all([
        getProductDetail(bodyProduct),
        getDetailProductRedeem(bodyRedeem),
      ])
      .then(response => {
        const [{ data: { data: product } }, { data: { data: redeem }}] = response
        let detailProduct = {}

        if (this.$route.query.edit) {
          detailProduct = {
            ...product,
            detail: {
              ...product.detail,
              title: redeem.product.title,
              long_description: redeem.product.long_description,
              category: {
                id: redeem.product.category,
                name: redeem.product.category_name,
              },
            },
            catalogs: product.catalogs.length > 0 ?
            product.catalogs?.map(v => {
              const load = {
                ...v,
                sku: redeem.product.catalogs?.filter(i => {
                  return v.id === i.id
                })[0]?.sku || v.sku,
                option: redeem.catalogs?.filter(i => {
                  return v.id === i.id
                })[0]?.option || v.option,
                title: redeem.catalogs?.filter(i => {
                  return v.id === i.id
                })[0]?.title,
              }
              return load
            }) : redeem.product.catalogs,
            images: redeem.product.images,
            inventory: redeem.inventory,
          }
        }

        this.product = this.$route.query.edit ? detailProduct : product
      })
      .catch(error => {
        console.log({error})
        const err = error[0] || error[1]
        const code = err.response?.status
        this.loading = false
        this.errors.code = code
        this.errors.message = code === 404 ? 'Produk tidak ditemukan' : (err.response?.data?.message || 'Terjadi kesalahan')
      })
      .finally(() => this.loading = false)
    },
  },
})
</script>
